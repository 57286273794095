
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import MappingPage from "../../connection/components/Mapping.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AttributePage from './AttributePage.vue'

interface Res {
  list: [];
  total: number;
}
@Options({
  components: {
    MappingPage,
    FontAwesomeIcon,
    AttributePage,
  },
})
export default class ConnectionPage extends Vue {
  titlePage = "Connection";
  titleModalConnect = "";
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  url = `${process.env.VUE_APP_BASE_URL}/`;
  connectData: any = [];
  platformData: any = [];
  dataConnect: any = {};
  applicationIds: any = {};
  callbacks: any = {};
  listCronDelete = [{}];
  listConnectionExpiryDate = [{ _id: "" }];
  listScript = [
    {
      connectFrom: { _id: "" },
      connectTo: { _id: "" },
      expiried: false,
      status: false,
    },
  ];
  dialogAddConnect = false;
  connectFrom = "";
  disabledSelect = false;
  options = [];
  idConnect = "";
  typeMapping = "";
  isMapping = false;
  eventUnregisterers: any = [];
  showListTarget = false;
  visible = false;
  objDiv: any;
  created() {
    this.getListConnect();
    this.getListScript();
    this.getListPlatform();
  }
  async getListConnect() {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: any = {};
    res = await axios.get("user/connect/list", {
      headers: this.headers,
    });
    if (res.data) {
      this.connectData = res.data;
      for (let connect of this.connectData) {
        if (connect.connectFrom) {
          this.connectFrom = connect.type;          
        }
      }
      this.applicationIds = res.applicationIds;
      this.callbacks = res.callbacks;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async getListScript(page = 0) {
    let res: Res = {
      list: [],
      total: 0,
    };
    await this.$store.dispatch("setLoading", true, { root: true });
    res = await axios.get("user/setting-script", {
      headers: this.headers,
      params: {
        account: localStorage.getItem("account"),
        page: page,
        limit: 100,
      },
    });
    if (res) {
      this.listScript = res.list;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async getListPlatform() {
    let res: any = {};
    res = await axios.get("admin/platform", {
      headers: this.headers,
    });
    if (res) {
      this.platformData = res.data;
    }
  }

  handleOpenConnect(connect: any) {
    this.dataConnect = {};
    if (connect) {
      this.titleModalConnect = `${connect.name} connection`;
      let data = this.platformData.filter(
        (platform: any = {}) => platform._id == connect.platform._id
      );
      this.options = data[0].options;
      for (let item of data[0].options) {
        this.dataConnect[item.key] = connect[item.key];
      }
      this.dataConnect.name = connect.name;
      this.dataConnect.type = connect.type;
      this.dataConnect.id = connect._id;
      this.dataConnect.connectFrom = connect.connectFrom;
      this.dataConnect.connectTo = connect.connectTo;
      this.disabledSelect = true;
    } else {
      this.titleModalConnect = `Add new connection`;
      this.dataConnect.connectFrom = false;
      this.dataConnect.connectTo = true;
      this.disabledSelect = false;
    }
    this.dialogAddConnect = true;
  }
  handleOption() {
    for (let platform of this.platformData) {
      if (platform.type === this.dataConnect.type) {
        this.dataConnect.platform = platform._id;
        this.dataConnect.price = platform.price;
        this.dataConnect.discount = platform.discount;
        this.options = platform.options;
        for (let item of platform.options) {
          this.dataConnect[item.key] = "";
        }
      }
    }
  }
  async handleSave() {
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: any;
    if (this.dataConnect.id) {
      res = await axios.put("user/connect", this.dataConnect, {
        headers: this.headers,
      });
    } else {
      res = await axios.post("user/connect", this.dataConnect, {
        headers: this.headers,
      });
    }

    if (res.status) {
      this.$message.success(res.message);
      this.dialogAddConnect = false;
      this.showListTarget = false;
      this.getListConnect();
    } else {
      this.$message.warning(res.message);
    }

    await this.$store.dispatch("setLoading", false, { root: true });
  }
  handleMapping(id: string) {
    this.isMapping = false;
    if (id) {
      this.idConnect = id;
      this.isMapping = true;
    }
  }
  closeModalMapping() {
    this.isMapping = false;
  }
  truncate(string: any) {
    if (string.length > 20) return string.substring(0, 20) + "...";
    else return string;
  }
}
